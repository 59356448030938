import React from 'react'

import '../assets/css/footer.css'

export default function Footer() {
    return (
        <footer className="page-footer">
            <p>
                &copy; {new Date().getFullYear()} <span>Angga Hermawan</span>. Built with{" "}
                <a href="https://www.gatsbyjs.com/">Gatsby</a>
            </p>
        </footer>
    )
}
