import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function Error() {
  return (
    <Layout>
      <main className="error-page">
        <section>
          <h1>404</h1>
          <h3>page not found</h3>
        </section>
      </main>
    </Layout>
  )
}

export const Head = props => (
  <Seo
    title="Error | Angga Hermawan"
    description={`Error website Angga Hermawan`}
  />
)
