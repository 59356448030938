import React from "react"
import { motion, AnimatePresence } from "framer-motion"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

// import "bootstrap/dist/css/bootstrap.min.css"
import "normalize.css"
import "../assets/css/global.css"

const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
    x: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: 200,
    transition: { duration: duration },
  },
}

export default function Layout({ children }) {
  return (
    <>
      <Navbar />
      {/* {children} */}

      <AnimatePresence>
        <motion.main
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
    </>
  )
}
